<template>
    <a-modal
        :title="title"
        :visible.sync="visible"
        okText="确定"
        cancelText="取消"
        :width="550"
        @cancel="close()"
        @ok="postData()"
    >
        <a-spin :spinning="loading">
            <a-form :form="form">
                <a-form-item label="登录账号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input :value="user.account" disabled></a-input>
                </a-form-item>
                <a-form-item label="登录密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入登录密码" v-decorator="['pwd', {rules: ['pwd',{required: true, min:6, max: 16, message: '请输入登录密码'}], trigger: 'blur'}]"></a-input>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>
<script>
import md5 from 'js-md5'
export default {
    data () {
        return {
            title: '重置密码',
            visible: false,
            loading: false,
            form: this.$form.createForm(this),
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            user: { account: '' }
        }
    },
    methods: {
        show (user) {
            this.user = user
            this.visible = true
        },
        close () {
            this.visible = false
        },
        postData () {
            this.form.validateFields((error, values) => {
                if (!error) {
                    let params = {
                        id: this.user.id,
                        pwd: md5(values['pwd'])
                    }
                    this.loading = true
                    this.$api.userAccount.resetUserPwd(params).then(res => {
                        if (res.status === 0) {
                            this.$message.success(res.msg)
                            this.close()
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    }).finally(_ => {
                        this.loading = false
                    })
                }
            })
        }
    }
}
</script>
